import React from "react"

import Layoutde from "../../components/layoutde/layoutde"
import SEO from "../../components/seo"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import Footer from "../../components/footer/footer"

import "../index.scss"
import "../services.scss"

import ImgLogo1 from "../../images/techstack/figma.svg"
import ImgLogo2 from "../../images/techstack/adobe-creative-suite.svg"
import ImgLogo3 from "../../images/techstack/trello.svg"
import ImgLogo4 from "../../images/techstack/toggl.svg"
import ImgLogo5 from "../../images/techstack/slack.svg"
import ImgLogo6 from "../../images/techstack/powerbi.svg"

import ImgLogo7 from "../../images/techstack/php.svg"
import ImgLogo8 from "../../images/techstack/nette.svg"
import ImgLogo9 from "../../images/techstack/mysql.svg"
import ImgLogo10 from "../../images/techstack/elasticsearch.svg"
import ImgLogo11 from "../../images/techstack/docker.svg"
import ImgLogo12 from "../../images/techstack/kubernetes.svg"
import ImgLogo13 from "../../images/techstack/angular.svg"
import ImgLogo14 from "../../images/techstack/bootstrap.svg"
import ImgLogo15 from "../../images/techstack/github.svg"
import ImgLogo16 from "../../images/techstack/bitbucket.svg"
import ImgLogo17 from "../../images/techstack/gitlab.svg"

import ImgLogo18 from "../../images/techstack/sentry.svg"
import ImgLogo19 from "../../images/techstack/uptimerobot.svg"
import ImgLogo20 from "../../images/techstack/aws.svg"
import ImgLogo21 from "../../images/techstack/selenium.svg"
import ImgLogo22 from "../../images/techstack/owasp.svg"

import VideoBg from "../../videos/ftrs-video.mp4"

const WasWirTun = () => (

    <Layoutde>
        <SEO title="Was Wir Tun" description="Wir begleiten Sie von der Idee bis zum fertigen Produkt." />

		<div className="ftrs-background" data-sal="fade" data-sal-duration="2000">
			<video autoPlay="autoplay" muted loop className="ftrs-hero__video ftrs-hero__video--dark">
				<source media="(orientation: landscape)" src={VideoBg} type="video/mp4" />
			</video>
		</div>

        <Container className="ftrs-content ftrs-services">

            <h1 data-sal="fade" data-sal-duration="1000" className="ftrs-h1">Produkte und&nbsp;Dienstleistungen</h1>

            <p data-sal="fade" data-sal-duration="1000" className="ftrs-services__paragraph text-basic mt-5">Die Entwicklung eines wertvollen Produkts ist eine&nbsp;lange Reise voller Validierungen, Tests, Designgestaltung, Entwürfe und&nbsp;Änderungen an&nbsp;der&nbsp;Softwarearchitektur, UX/UI, Wartung und&nbsp;Optimierung.</p>
            <p data-sal="fade" data-sal-duration="1000"><strong>Gerne begleiten wir Sie auf&nbsp;diesem Weg, damit Sie Ihre&nbsp;Ziele sicher erreichen.</strong></p>

		</Container>

		<div className="ftrs-services__box-stage">
			<Container>

				<Row>
					<Col lg="6">
						<h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-h2--big mb2">Projekt, Plan und&nbsp;Design</h2>
						<p data-sal="fade" data-sal-duration="1000" className="ftrs-services__header-text">
							Wir helfen Ihnen bei&nbsp;der&nbsp;Planung und&nbsp;Organisation Ihres Projekts. Gemeinsam mit&nbsp;einem&nbsp;Team von&nbsp;erfahrenen Fachleuten bringen wir jedes Projekt auf&nbsp;die&nbsp;nächste Stufe. Wir&nbsp;machen Ihr&nbsp;digitales Produkt großartig.
						</p>
						<ul data-sal="fade" data-sal-duration="1000" className="mb2">
							<li>Produktarchitektur und&nbsp;-design</li>
							<li>Verständnis Ihrer geschäftlichen Anforderungen</li>
							<li>Innovation und&nbsp;Kreativität</li>
							<li>Planung und&nbsp;Prioritätensetzung</li>
							<li>Projektleitung</li>
						</ul>
					</Col>
					<Col lg="6">
						<div className="ftrs-services__icons">
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo1} alt="Figma" />
								</div>
								<div className="ftrs-techstack__header">Figma</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo3} alt="Trello" />
								</div>
								<div className="ftrs-techstack__header">Trello</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo4} alt="Toggl" />
								</div>
								<div className="ftrs-techstack__header">Toggl</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo5} alt="Slack" />
								</div>
								<div className="ftrs-techstack__header">Slack</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo6} alt="Power BI" />
								</div>
								<div className="ftrs-techstack__header">Power BI</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo2} alt="Adobe Creative Suite" />
								</div>
								<div className="ftrs-techstack__header">Creative Suite</div>
							</div>
						</div>
					</Col>
				</Row>

				<Row className="mt4">
					<Col lg="6">
						<h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-h2--big mb2">Präzises Handwerk</h2>
						<p data-sal="fade" data-sal-duration="1000" className="ftrs-services__header-text">
							Gemeinsam mit unseren Entwicklern berücksichtigen wir während dem&nbsp;ganzen Projekt alle Pläne, Termine und&nbsp;Sicherheitsstandarde. Mit&nbsp;den&nbsp;neuesten Technologien und&nbsp;Trends helfen wir Ihnen, ein&nbsp;Produkt zu&nbsp;erstellen, das&nbsp;Ihre Benutzer gerne verwenden werden.
						</p>
						<ul data-sal="fade" data-sal-duration="1000" className="mb2">
							<li>Entwicklung von&nbsp;Web- und&nbsp;Mobile Apps</li>
							<li>Neueste Technologien</li>
							<li>Qualitätskontrolle</li>
							<li>Fokus auf&nbsp;Sicherheit</li>
						</ul>
					</Col>
					<Col lg="6">
						<div className="ftrs-services__icons">
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo7} alt="PHP" />
								</div>
								<div className="ftrs-techstack__header">PHP</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo8} alt="Nette" />
								</div>
								<div className="ftrs-techstack__header">Nette</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo9} alt="MySQL" />
								</div>
								<div className="ftrs-techstack__header">MySQL</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo10} alt="Elasticsearch" />
								</div>
								<div className="ftrs-techstack__header">Elasticsearch</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo11} alt="Docker" />
								</div>
								<div className="ftrs-techstack__header">Docker</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo12} alt="Kubernetes" />
								</div>
								<div className="ftrs-techstack__header">Kubernetes</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo13} alt="AngularJS" />
								</div>
								<div className="ftrs-techstack__header">AngularJS</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo14} alt="Bootstrap" />
								</div>
								<div className="ftrs-techstack__header">Bootstrap</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo15} alt="Github" />
								</div>
								<div className="ftrs-techstack__header">Github</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo16} alt="Bitbucket" />
								</div>
								<div className="ftrs-techstack__header">Bitbucket</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo17} alt="Gitlab" />
								</div>
								<div className="ftrs-techstack__header">Gitlab</div>
							</div>
						</div>
					</Col>
				</Row>


				<Row className="mt4">
					<Col lg="6">
						<h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-h2--big mb2">Digital Partnering</h2>
						<p data-sal="fade" data-sal-duration="1000" className="ftrs-services__header-text">
							Mit dem Launch einer&nbsp;App ist es aber nicht getan. Digitale Produkt müssen, um&nbsp;großartig zu&nbsp;bleiben, laufend weiterentwickelt und&nbsp;gewartet werden. Und&nbsp;hier kommt unser Team ins&nbsp;Spiel, dem&nbsp;es ein&nbsp;Anliegen ist, Ihre App immer auf&nbsp;dem&nbsp;aktuellsten Niveau zu&nbsp;halten. Devops-Ingenieure lieben es, mit&nbsp;Cloud-Diensten zu&nbsp;arbeiten, die&nbsp;sicher und&nbsp;skalierbar sind.
						</p>
						<ul data-sal="fade" data-sal-duration="1000" className="mb2">
							<li>laufende digitale Unterstützung</li>
							<li>Wartung</li>
							<li>DevOps</li>
						</ul>
					</Col>
					<Col lg="6">
						<div className="ftrs-services__icons">
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo20} alt="AWS" />
								</div>
								<div className="ftrs-techstack__header">AWS</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo18} alt="Sentry" />
								</div>
								<div className="ftrs-techstack__header">Sentry</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo19} alt="Uptimerobot" />
								</div>
								<div className="ftrs-techstack__header">Uptimerobot</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo21} alt="Selenium" />
								</div>
								<div className="ftrs-techstack__header">Selenium</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo22} alt="Owasp" />
								</div>
								<div className="ftrs-techstack__header">Owasp</div>
							</div>
						</div>
					</Col>
				</Row>


			</Container>
		</div>

		<Container>

			<h2 className="ftrs-h2 ftrs-h2--big">Was wir am&nbsp;besten können?</h2>

            <Row>
                <Col xs="12" xl="4">
                    <h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-services__header">Sektor</h2>
                    <ul data-sal="fade" data-sal-duration="1000" className="ftrs-services__list">
                        <li>Bankwesen</li>
                        <li>Automobilindustrie</li>
                        <li>Versicherung</li>
                        <li>Telco</li>
                        <li>Einzelhandel</li>
                        <li>Smart Cities</li>
                    </ul>
                </Col>
                <Col xs="12" xl="4">
                    <h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-services__header">Kategorien</h2>
                    <ul data-sal="fade" data-sal-duration="1000" className="ftrs-services__list">
                        <li>Content-Management</li>
                        <li>Marktforschung</li>
                        <li>Umfrage und&nbsp;Berichtswesen</li>
                        <li>Business Intelligence</li>
                        <li>Competitive Intelligence</li>
                        <li>Kundenerfahrung</li>
                    </ul>
                </Col>
                <Col xs="12" xl="4">
                    <h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-services__header">Fachwissen</h2>
                    <ul data-sal="fade" data-sal-duration="1000" className="ftrs-services__list">
                        <li>Datenanalytik</li>
                        <li>Komplexe organisationsübergreifende Kommunikation</li>
                        <li>Intelligentes E-Mailing</li>
                        <li>UX-Design</li>
                    </ul>
                </Col>
            </Row>

        </Container>

        <Footer lang='de'></Footer>

    </Layoutde>

)

export default WasWirTun